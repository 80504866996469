<template>
  <div class="report-display">
    <my-search-bar>
      <el-form :inline="true" class="mt-2">
        <el-form-item>
          <el-select
            v-model="tenderGuid"
            placeholder="标段"
            clearable>
            <el-option
              v-for="tender in tenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="date"
            type="date"
            format="yyyyMMdd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <div class="report-wrapper">
      <div class="report-item"
        v-for="report in reports"
        :key="report.reportGuid"
        >
        <div class="bg-info" @click="handleClick(report, 'pdf', true)">
          <i :class="report.iconClass"></i>
        </div>
        <div class="buttons-wrapper">
          <span style="font-size: 14px; padding-bottom: 2px;">
            {{ report.reportAlias }} {{ report.reportName }}
          </span>
          <div class="icons btn-group btn-group-sm">
            <button class="btn" @click="handleClick(report, 'pdf')">
              <i class="fas fa-file-pdf"></i>
            </button>
            <button class="btn" @click="handleClick(report, 'docx')">
              <i class="fas fa-file-word"></i>
            </button>
            <button class="btn" @click="handleClick(report, 'xlsx')">
              <i class="fas fa-file-excel"></i>
            </button>
            <button class="btn" @click="handleClick(report, 'png')">
              <i class="fas fa-file"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <my-dialog
      ref="myDialog"
      :title="dialogTitle"
      :hide-ok="true"
      cancel-text="关闭"
      body-height="700px"
      size="xl">
      <iframe ref="myIFrame" :src="url" width="100%" height="100%" scrolling="no" frameborder="0"></iframe>
    </my-dialog>
  </div>
</template>

<script>
import reportService from '@/services/reportService'
import examinationService from '@/services/examinationService'
import tenderService from '@/services/tenderService'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import utility from '@/common/utility'
import config from '@/config'
import auth from '@/common/auth'
import PageInfo from '@/support/PageInfo'
import axios from 'axios'

export default {
  name: 'ReportDisplay',
  components: {
    MyDialog,
    MySearchBar
  },
  data () {
    return {
      tenderGuid: '',
      date: '',
      reports: [],
      tenders: [],
      periods: [],
      url: '',
      dialogTitle: '查看报表'
    }
  },
  watch: {
    tenderGuid (newVal) {
      this.periods = []
      this.date = ''
      this.reports = []
      this.url = ''
      if (newVal) {
        this._getPeriods(newVal)
        this._getReports(this._getReportTemplateGuid(newVal))
      }
    }
  },
  computed: {
  },
  methods: {
    handleClick (report, format, inline = false) {
      if (!this.date) {
        this.$message({
          type: 'error',
          message: '请选择日期'
        })
        return
      }
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.url = `${config.reportHost}/reportjhtj?format=${format}&inline=${inline}&date=${this.date}&tender=${this.tenderGuid}&report=${report.reportGuid}&timestamp=${timestamp}&parameter=${token}`
      // this.url = `${config.reportHost}/reportjhtj?format=${format}&inline=${inline}&examinations=3f6daab0-6b32-11ea-9749-ff6482a309df&report=${report.reportGuid}&timestamp=${timestamp}&parameter=${token}`

      axios.get(this.url)
        .then(res => {
          if (inline) {
            this.dialogTitle = report.reportAlias + ' ' + report.reportName
            this.$refs.myDialog.open()
          } else {
            window.open(this.url, '_blank')
          }
        })
        .catch(err => {
          console.log(err)
          if (inline) {
            this.dialogTitle = report.reportName + ' ' + report.reportAlias
            this.$refs.myDialog.open()
          } else {
            window.open(this.url, '_blank')
          }
        })
    },
    _getReportTemplateGuid (tenderGuid) {
      for (let i = 0; i < this.tenders.length; ++i) {
        if (this.tenders[i].tenderGuid === tenderGuid) {
          return this.tenders[i].reportTemplateGuid
        }
        return ''
      }
    },
    _getReports (reportTemplateGuid) {
      reportService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'reports.sortId asc' }), { reportTemplateGuid: reportTemplateGuid, reportType: '计划统计' })
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.reports = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    _getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    },
    _getPeriods (tenderGuid) {
      examinationService.getAddedPeriods(tenderGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.periods = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    }
  },
  created () {
    this._getTenders()
  }
}
</script>

<style scoped lang="scss">
.report-item {
  display: flex;
  flex-direction: row;
  float: left;
  margin: 0 18px 18px 0;
  margin-left: 0;
  margin-right: 15px;
  padding: 7px;
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0 0 5px #eee;
}

.bg-info {
  width: 64px;
  height: 64px;
  line-height: 64px;
  color: white;
  text-align: center;
  border-radius: 10px;
}

.report-item i {
  font-size: 30px;
}
.report-item:hover {
  color: rgb(30, 170, 192);
}

.buttons-wrapper {
  box-sizing: border-box;
  width: 300px;
  padding-left: 15px;
  padding-top: 3px;
  display: flex;
  flex-direction: column;
}
.btn {
  border-right: 1px solid #ddd;
}
.btn:last-child {
  border-right: 0;
}
.btn:hover {
  background-color: #ddd;
}

.btn i {
  font-size: 14px;
}
.icons {
  width: 130px;
  height: 32px;
  border-radius: 5px;
  line-height: 32px;
  box-sizing: border-box;
  background-color: rgb(244, 244, 244);
  border: 1px solid #ddd;
  margin-top: 6px;
  margin-left: 155px;
}
</style>
